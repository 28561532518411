import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from "@mui/icons-material/BarChart";
import PersonIcon from "@mui/icons-material/Person";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ForumIcon from "@mui/icons-material/Forum";

export const navData = [
  {
    id: 0,
    icon: <HomeIcon />,
    text: "Dashboard",
    link: "/dashboard",
    role: ["executive", "admin"],
  },
  {
    id: 1,
    icon: <PersonIcon />,
    text: "User Management",
    link: "/admin/user-management",
    role: ["admin"],
  },

  // {
  //   id: 4,
  //   icon: <SettingsIcon />,
  //   text: "Settings",
  //   link: "/admin/settings",
  //   role: ["admin"],
  // },
  {
    id: 5,
    icon: <AppRegistrationIcon />,
    text: "Data Form",
    link: "/data-form",
    role: ["executive", "admin"],
  },
  {
    id: 6,
    icon: <LibraryBooksIcon />,
    text: "KPI Reports",
    link: "/kpi-reports",
    role: ["executive", "admin"],
  },
  {
    id: 7,
    icon: <BarChartIcon />,
    text: "Gemba",
    link: "/gemba",
    role: ["executive", "admin"],
  },
  {
    id: 8,
    icon: <ForumIcon />,
    text: "Remarks",
    link: "/remarks",
    role: ["executive", "admin"],
  },
];
