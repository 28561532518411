import React, { useState } from "react";
import img1 from "../../assets/images/p1.png";
import img2 from "../../assets/images/p3.png";
import img3 from "../../assets/images/p2.png";
import bgimg1 from "../../assets/images/addedNew/dossier-raw.jpg.png";
import bgimg2 from "../../assets/images/addedNew/supplier-raw.jpg.png";
import bgimg3 from "../../assets/images/addedNew/services-raw.jpg.png";
import FirstButton from "../../common/button";
import { useNavigate } from "react-router-dom";

const ShowCards = () => {
  const navigate = useNavigate();
  const [hoverIndex, setHoverIndex] = useState(null);
  const data = [
    {
      title: "Dossier Finder",
      desc: "Licensing, Acquisition of dossiers for Finished dosages for global markets",
      heading: "Find the right product",
      image: img1,
      bgImg: bgimg1,
    },
    {
      title: "Supplier Finder",
      desc: "Finished dosage suppliers, Manufacturing partners, CDMOs.",
      heading: "Connect with the right supplier",
      image: img3,
      bgImg: bgimg2,
    },
    {
      title: "Services",
      desc: " Product Development, Analytical Services, Bio-availability/ Bio-equivalence services, Clinical supplies",
      heading: "Choose from an array of services ",
      image: img2,
      bgImg: bgimg3,
    },
  ];

  return (
    <>
      <div className="showCards">
        <div style={{ textAlign: "center" }} className="showCards_container">
          <div className="cards" onClick={() => navigate("/login")}>
            {data.map((item, index) => (
              <div
                key={index}
                className="card"
                style={{
                  backgroundImage:
                    hoverIndex === index
                      ? `linear-gradient(
                rgba(177, 70, 203, 0.8),
                rgba(75, 54, 120, 0.8)
              ),
              url(${item?.bgImg})`
                      : `linear-gradient(
                to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 180%
              ),
              url(${item?.bgImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  transition: "background-image 0.3s ease-in-out",
                }}
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(null)}
              >
                <p className="fs-18 fw-600">{item.heading}</p>
                <h1 className="card_heading"> {item.title}</h1>
                <p
                  style={{ marginBottom: "5rem" }}
                  className="card_text card_height"
                >
                  {item.desc}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowCards;
