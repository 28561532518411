import React, { useEffect } from "react";
import FirstButton from "../common/button";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "./BreadCrumbs";
const ProductDetails = () => {
  const navigate = useNavigate();
  const urls = [
    { name: "Home", url: "/home" },
    { name: "Dossier Search", url: "/dossier-search" },
    { name: "Product Details", url: "" },
  ];
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div className="p14 backgroundColor">
      <BreadCrumbs array={urls} />
      <div className="p-4 bg-white mt-4">
        <div className="ps_main mb-4">
          <h1 className="fw-600">Product Details</h1>
          <FirstButton
            className="borderbtn"
            buttonText="Back to Search Results"
            width="30%"
            onClick={() => navigate("/dossier-search")}
          />
        </div>
        <div className="ps_main">
          <h3 className="fw-600">Dexlansoprazole</h3>
          <p className="fs-14 fw-600 mb-0">Code-10115</p>
          <FirstButton
            className="btn"
            buttonText="Request Term Sheet"
            width="30%"
            onClick={() => navigate("/product-term-sheet")}
          />
        </div>
      </div>
      <div>
        <hr />
        <div className="dfcg3">
          <div className="flex1 ">
            <h2 className="my-2  heading-bg-color text-white">
              Product/ Dossier details
            </h2>
            <table class="table table-striped bx-shadow">
              <tbody>
                {productdetails &&
                  productdetails.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div
              className="my-2 bx-shadow bg-white"
              style={{ padding: "2rem" }}
            >
              <p className="fs-16 lh-base">
                This is a curated dossier which means at PharmaDigm, we have
                done a due diligence and the report can be made available
              </p>
              <p className="fs-16 lh-base">
                The seller is a large generic company head quartered in India
                and originally developed the product for own commercialisation.
                Due to a change of strategy, they are now looking for a
                licensing partner.{" "}
              </p>
              <p className="fs-16 lh-base">
                The seller will support the submission and queries from own
                facility which has all regulated market GMPs. Post approval, the
                buyer is required to tech transfer to an alternate facility.
                PharmaDigm can identify a CDMO with a similar manufacturing
                equipment for ease of tech transfer.{" "}
              </p>
              <p className="fs-16 lh-base">
                The seller's dossier can be used for other reg markets due to
                similarities in ref product. BE for Ex-US markets will have to
                be performed by Buyer at his own cost. PharmaDigm has a list of
                CROs with prior experience of the molecule.{" "}
              </p>
              <p className="fs-16 lh-base">
                {" "}
                Dossier due diligence can be arranged after initial commercials
                are agreed and upon signing of a term sheet.
              </p>
              <p className="fs-16 lh-base">
                Acoording to PharmaDigm, Dexlansoprazole has sales of $ mn in
                the US, Y mn in EU and there are no generics as on date
              </p>
            </div>
          </div>
          <div className="flex1">
            <h2 className="my-2  heading-bg-color text-white">Deal Contours</h2>
            <table class="table table-striped bx-shadow">
              <tbody>
                {dealCounter &&
                  dealCounter.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <h2 className="my-2  heading-bg-color text-white">
              Manufacturing Details
            </h2>
            <table class="table table-striped bx-shadow">
              <tbody>
                {manufDetails &&
                  manufDetails.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <FirstButton
              className="btn my-2"
              buttonText="Request Term Sheet"
              width="100%"
              onClick={() => navigate("/product-term-sheet")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;

const productdetails = [
  { label: "Dose Form 1", value: "Hard gelatin capsules" },
  { label: "Dose Form 2", value: "Delayed Release" },
  { label: "Route of Administration", value: "Oral" },
  { label: "Strengths", value: "30/60mg" },
  { label: "Salt", value: "Base" },
  { label: "Therap. Category", value: "Proton Pump Inhibitor" },
  { label: "ATC Class", value: "Alimentary Tract & Metabolism" },
  {
    label: "Indication",
    value:
      "To treat symptoms of erosive esophagitis, gastroesophageal reflux disease",
  },
  { label: "US Ref product*", value: "DEXILENT (N022287)" },
  { label: "US Constraint date", value: "Mar 2027" },
  { label: "Rx/ OTC", value: "Rx" },
  { label: "Pack style 1", value: "HDPE bottle" },
  { label: "Pack sizes", value: "30,60,100,1000" },
  { label: "Pack style 2", value: "PVC Al blister" },
  { label: "Pack sizes", value: "strips of 10" },
  { label: "CT/ BE details", value: "BE against US RLD successful" },
  {
    label: "Dossier status",
    value: "US complete, Ex- US - BE to be performed",
  },
  { label: "Registered in", value: "MENA" },
];

const dealCounter = [
  { label: "Available geographies", value: "US, Global" },
  { label: "Supply status", value: "No commercial supplies" },
  { label: "Tech transfer support", value: "Can be provided" },
  { label: "Deal type", value: "Dossier licensing no supply" },
  { label: "Exclusivity", value: "Can be discussed" },
  { label: "MA holder", value: "Buyer" },
  { label: "IP owner", value: "Seller" },
  {
    label: "Details",
    value:
      "Seller to license IP and technical know how to Buyer. Buyer to be owner of the MA",
  },
  { label: "One time fee", value: "Between USD 1-1.5 mn for US" },
  { label: "Royalty", value: "TBD" },
  { label: "Profit share", value: "Not applicable" },
];
const manufDetails = [
  { label: "R&D details", value: "Own Development" },
  { label: "Submission batch mfg", value: "Own facility" },
  { label: "GMP status", value: "US, UK, EU, HC, TGA, RU" },
  { label: "Last Inspection", value: "US (Date), EU (Date)" },
  { label: "Country of manufacture", value: "IND" },
  { label: "# of API sources", value: "1" },
  { label: "API type", value: "Third party" },
  { label: "API DMF", value: "US DMF" },
  { label: "CPP country", value: "Available, IND" },
  { label: "Batch size", value: "None" },
  { label: "MOQ", value: " " },
  { label: "CRO", value: "IND" },
  { label: "Stability ", value: "Acc 6m/ Real 12m" },
  { label: "Stability Zones", value: "Zone 1" },
  { label: "", value: "Zone 2" },
  { label: "", value: "Zone 4" },
  { label: "Zone IV stability", value: "Available (blisters)" },
];
