import React, { useState, useEffect } from "react";
// import Sidebar from "./Sidebar";
import { Tabs, Tab } from "@mui/material";
import InvestorFaq from "../components/Faq/InvestorFaq";

const tablist = ["Frequently Asked Questions (FAQs)  "];
const Faq = () => {
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  const handleChange = (e, value) => {
    setTabIndex(value);
  };
  return (
    <div className="dflex ">
      <div className="w100 p132 w-90">
        {/* <h1 className="faq_f1 faq_mr "> FAQs</h1> */}
        <h1 className="primary_title about_heading"> FAQs</h1>
        <div className="faq_mr">
          {/* <h2 className="faq_f2">How can we help you?</h2> */}
          {/* <input className="login_input" placeholder="Search" /> */}
        </div>
        <div>
          <Tabs
            variant="fullWidth"
            onChange={handleChange}
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#4c8df5",
              },
            }}
          >
            {tablist.map((item, index) => (
              <Tab
                style={{
                  fontSize: "1.6rem",
                  color: `${tabIndex === index ? "#4c8df5" : "black"}`,
                  fontWeight: `${tabIndex === index ? "bold" : 300}`,
                  textTransform: "none",
                  fontFamily: "Montserrat",
                }}
                value={index}
                label={item}
                key={index}
              />
            ))}
          </Tabs>
          {tabIndex === 0 && <InvestorFaq />}
        </div>
      </div>
    </div>
  );
};

export default Faq;
