import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
const BreadCrumbs = (props) => {
  const breadcrumbData = props?.array;

  return (
    <div className="mb-2">
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbData?.map((item, index) => (
          <Link
            key={index}
            className={
              item?.url === ""
                ? "fs-18 fw-500 text-dark pe-none"
                : "fs-18 fw-500"
            }
            underline="hover"
            color="inherit"
            to={item.url}
          >
            {item.name}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default BreadCrumbs;
