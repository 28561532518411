import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import Goals from "../components/HowitWorks/Goals";
const tablist = ["Step-1", "Step-2", "Step-3", "Step-4"];
const HowItWorks = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [role, setRole] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  const handleChange = (e, value) => {
    setTabIndex(value);
  };
  const handleSubmit = (value, id) => {
    setTabIndex(value);
    setRole(id);
  };
  return (
    <div className="signupoffounders_main">
      <Goals />
    </div>
  );
};

export default HowItWorks;
