import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";
import scrollto from "../../assets/images/logo/ScrolltoTop.png";
const InvestorFaq = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  const scrolltotop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {question.map((item, index) => (
        <Accordion
          key={index}
          style={{ boxShadow: "none", border: "none", padding: "0" }}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#4c8df5", fontSize: "1.6rem" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ padding: "0 !important", border: "0 !important" }}
          >
            <p className="faq_f3 faq_f600">{item.ques}</p>
          </AccordionSummary>

          <AccordionDetails>
            <div
              className="faq_f3"
              dangerouslySetInnerHTML={{ __html: item.ans }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <div className="dflex alcenter faq_mr3">
        <p className="faq_f3">
          If you can not find what you are looking for,Email Us.
          <span>
            {" "}
            <Link className="faq_f600" to="/contact-us">
              Email us{" "}
            </Link>
          </span>
        </p>
        <img src={scrollto} onClick={scrolltotop} />
      </div>
    </div>
  );
};

export default InvestorFaq;

const question = [
  {
    ques: "What is PharmaDigm?",
    ans: "PharmaDigm is a pioneering B2B marketplace designed for pharmaceutical deal-making. We specialize in the licensing of finished dosage form dossiers & manufacturing and related services for global markets, bringing a new level of intelligence and simplicity to the pharmaceutical industry.",
  },
  {
    ques: "How does PharmaDigm work?",
    ans: "PharmaDigm simplifies the deal-making process. Buyers can explore a wide range of pharmaceutical products, compare detailed dossiers, request tailored term sheets, and engage with suppliers. The platform is designed to streamline the entire transaction journey.",
  },
  {
    ques: "Who can use PharmaDigm?",
    ans: "PharmaDigm caters to pharmaceutical industry professionals, business development professionals, including buyers and sellers looking for efficient and transparent deal-making - licensing, tech transfer, contract manufacturing, asset buying opportunities globally for finished dosage forms.",
  },
  {
    ques: " What sets PharmaDigm apart from traditional networking?",
    ans: "Unlike traditional networking, PharmaDigm offers a digital platform that leverages intelligence and simplicity. It provides a global hub for licensing finished dosage form dossiers, eliminating the constraints of conventional networking. Comparing multiple dossiers or suppliers to make the right choice is now at the click of a button",
  },
  {
    ques: "Is registration on PharmaDigm mandatory?",
    ans: "Yes, registration is required to unlock the full buyer experience on PharmaDigm. The registration process is quick and straightforward, providing access to a wealth of pharmaceutical opportunities.",
  },
  {
    ques: "How can I explore products on PharmaDigm?",
    ans: "Navigate through our user-friendly interface to explore a diverse range of pharmaceutical products. Use the product finder or apply specific filter criteria to streamline your search.",
  },
  {
    ques: "Can I compare products on PharmaDigm?",
    ans: "Absolutely! PharmaDigm allows buyers to conduct detailed comparisons. Evaluate available dossiers, assess GMP compliance, explore one-time fees, and review supply prices before making informed decisions.",
  },
];
