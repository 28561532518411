import { Button } from '@mui/material'; 
export default function FirstButton({
    buttonText,padding,margin,fontWeight,border,borderRadius,color,marginTop,float,onClick,className,width,backgroundColor
}
   
) {
      return (
        <Button className={className} style={{padding,margin,fontWeight,border,borderRadius,color,marginTop,float,width,backgroundColor}}  
        onClick={(event)=>onClick(event)}>{buttonText} </Button>
      )
  }

  