import React, { useEffect } from "react";

const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div className="login_main ">
      <div className="main_text">
        <h1 className="primaryText dark text-center">Coming Soon</h1>
      </div>
    </div>
  );
};

export default Service;
