import React from "react";
/* import Logo from "../../assets/images/logo/smlogo-full.svg"; */
import Logo from "../assets/images/logo/pdlogo.png";
import { Button } from "@mui/material";
import FirstButton from "../common/button";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="footer">
        <div className="footer_container">
          <div className="footer_section--1">
            <div className="footer_links">
              <div id="footer_main" className="footer_content">
                {/* <div className="navbar-brand">
                  <a className="d-flex" href="/">
                    <img className="brand-logo" alt="Logo" src={Logo} />
                    <span className="navbar_logo">PharmaDigm</span>
                  </a>
                </div>
                <p className="footer_text">Connect and Grow</p> */}

                <div className="navbar-brand">
                  <a className="d-flex" href="/">
                    <img className="brand-logo" alt="Logo" src={Logo} />
                    <div style={{ lineHeight: "18px" }}>
                      <span className="navbar_logo">PharmaDigm</span>
                      <p className="fs-12 text-white ps-2 text-start mb-0">
                        Connect & Grow
                      </p>
                    </div>
                  </a>
                </div>
                <div className="footer_socialIcons">
                  <i className="fa-brands fa-linkedin-in"></i>
                  <i className="fa-brands fa-twitter"></i>
                  <i className="fa-brands fa-google"></i>
                  <i className="fa-brands fa-facebook-f"></i>
                </div>

                {/* <div className="copy">
                  &#169; 2024 PharmaDigm. All rights reserved
                </div> */}
              </div>
              <div className="footer_content">
                {/*                 <h1 className="content_heading">For investors and Donors</h1>
                <ul className="footer_ul">
                  <li className="footer_li">Why invest/donate</li>
                  <li className="footer_li">How it works</li>
                  <li className="footer_li">FAQ</li>
                  <li className="footer_li">Type of securities</li>
                </ul> */}
              </div>
              <div className="footer_content">
                {/*                 <h1 className="content_heading">For Startups</h1>
                <ul className="footer_ul">
                  <li className="footer_li">Why Smarket</li>
                  <li className="footer_li">How to Raise</li>
                  <li className="footer_li">FAQ</li>
                </ul> */}
              </div>
              <div className="footer_content">
                <h1 className="content_heading">Support</h1>
                <ul className="footer_ul">
                  <li
                    className="footer_li sidebar_close color-border "
                    onClick={() => navigate("/how-it-works")}
                  >
                    How it works
                  </li>
                  <li
                    className="footer_li sidebar_close color-border"
                    onClick={() => navigate("/contact-us")}
                  >
                    Contact Us
                  </li>
                  <li
                    className="footer_li sidebar_close color-border"
                    onClick={() => navigate("/faq")}
                  >
                    FAQs
                  </li>
                </ul>
              </div>

              <div className="footer_content">
                <h1 className="content_heading">Services</h1>
                <ul className="footer_ul">
                  <li
                    className="footer_li sidebar_close color-border"
                    onClick={() => navigate("/dossier-search")}
                  >
                    Dossier Finder
                  </li>
                  <li
                    className="footer_li sidebar_close color-border"
                    onClick={() => navigate("/supplier-search")}
                  >
                    Supplier Finder
                  </li>
                  <li
                    className="footer_li sidebar_close color-border"
                    onClick={() => navigate("/service")}
                  >
                    Services Finder
                  </li>
                </ul>
              </div>

              <div className="footer_content">
                <h1 className="content_heading">Others</h1>
                <ul className="footer_ul">
                  <li
                    className="footer_li sidebar_close color-border"
                    onClick={() => navigate("/about-us")}
                  >
                    About Us
                  </li>
                  <li className="footer_li sidebar_close color-border">
                    Terms and conditions
                  </li>
                  <li className="footer_li sidebar_close color-border">Privacy policy</li>
                </ul>
              </div>
            </div>
          </div>
          {/*           <div className="footer_section--2">
            <h1 className="refer">
              Refer and earn upto ₹ 15000/-{" "}
              <span style={{ color: "#4c8df5" }}>Learn More</span>{" "}
            </h1>
            <Button id='mui_greenBtn' color="primary" size='medium' variant='contained'>Learn More</Button>
            <FirstButton className="whitebtn" buttonText="New" />
          </div> */}
          <div
            className="footer_section--3"
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="terms_li">
              2024 PharmaDigm. All rights reserved. Powered by Revaalo Labs Pvt
              Ltd
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
