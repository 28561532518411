import React, { useEffect, useState } from "react";
import FirstButton from "../common/button";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ContactsIcon from "@mui/icons-material/Contacts";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { IconButton, DialogContent, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import thankyou from "../assets/images/thankU.png";
import img1 from "../assets/images/contactUs/help.jpeg";
import img2 from "../assets/images/contactUs/rfp.jpeg";
import img3 from "../assets/images/contactUs/rm.jpeg";
const ContactUs = () => {
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [thankUOpen, setThankUOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  const handleOpen = (data) => {
    if (data === "contact") {
      setOpen(true);
    } else if (data === "thankyou") {
      setThankUOpen(true);
    }
  };
  //Function to close the dailog box
  const handleClose = () => {
    setOpen(false);
    setThankUOpen(false);
  };
  const handleSubmit = () => {
    setOpen(false);
    setThankUOpen(true);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "username") {
      setUsername(value);
    } else if (name === "Message") {
      setMessage(value);
    } else if (name === "phoneNo") {
      setPhoneNo(value);
    } else if (name === "email") {
      setEmail(value);
    }
  };
  return (
    <div className="search">
      <h1 className="primary_title text-center about_heading">Contact Us</h1>

      <p style={{ textAlign: "center" }} className="fs-18 my-2">
        Get in touch and let us know how we can help you
      </p>
      <div className="ph-csmain">
        {Contactdata &&
          Contactdata.map((item, index) => (
            <div className="ncs_card">
              {/* <div className="ncs_circle">{item.icon}</div> */}
              <div className="p-2">
                <img
                  src={item?.img}
                  style={{ width: "100%", borderRadius: "8px" }}
                />
              </div>
              <div>
                <FirstButton
                  buttonText={item.label}
                  className="btn-contact"
                  width="100%"
                  onClick={() => handleOpen("contact")}
                />
              </div>
            </div>
          ))}
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        open={open}
      >
        <div className="d-flex justify-content-between align-items-center p-3">
          <p className=" fs-16 fw-600 text-uppercase  m-auto  ">Contact US</p>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <DialogContent dividers>
          <div className="px-4 py-2">
            <input
              className="login_input  mb-4"
              value={username}
              name="username"
              onChange={handleChange}
              placeholder="Enter Your Name"
              id="outlined-basic"
              label="Name"
              variant="outlined"
            />

            <input
              className="login_input  mb-4"
              value={email}
              name="email"
              type="email"
              onChange={handleChange}
              placeholder="Enter Your Email"
              id="outlined-basic"
              label="Email"
              variant="outlined"
            />

            <input
              className="login_input  mb-4 "
              value={phoneNo}
              name="phoneNo"
              onChange={handleChange}
              placeholder="Enter Your Phone No"
              id="outlined-basic"
              label="Phone No"
              variant="outlined"
            />

            <textarea
              className="login_input mb-4"
              name="Message"
              rows="3"
              cols="30"
              onChange={handleChange}
              placeholder="Enter Your Message"
              value={message}
              id="outlined-basic"
              label="Password"
              variant="outlined"
            />

            <FirstButton
              className="btn "
              buttonText="Submit"
              marginTop="2rem"
              width="40%"
              onClick={handleSubmit}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        open={thankUOpen}
      >
        <div className="d-flex justify-content-end align-items-center p-3">
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <DialogContent dividers>
          <div className="px-4 py-2 text-center">
            <img
              alt=""
              src={thankyou}
              style={{ width: "20%", margin: "15px" }}
            />
            <h4 className="fs-20 fw-700">
              Thank you for your interest in PharmaDigm, we will get in touch
              with you shortly.
            </h4>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ContactUs;

const Contactdata = [
  {
    label: "Still unsure, we are happy to help!",
    icon: <ContactsIcon style={{ fontSize: "4rem" }} />,
    img: img1,
  },
  {
    label: "Talk to our sales team",
    icon: <ContactSupportIcon style={{ fontSize: "4rem" }} />,
    img: img2,
  },
  {
    label: "Key Account support",
    icon: <ContactPageIcon style={{ fontSize: "4rem" }} />,
    img: img3,
  },
];
