export const filters = [
  {
    id: 1,
    name: "Molecule",
    subFilters: ["ATC class", "Dosage form", "Route of Administration"],
  },
  {
    id: 2,
    name: "Region of Interest  ",
    subFilters: ["Asia", "Europe", "North America", "Rest of the World"],
  },
  { id: 3, name: "Constraint Date", subFilters: ["Before", "After"] },
  {
    id: 4,
    name: "Country of manufacture ",
    subFilters: ["Asia", " Europe", "North America", "Rest of the World"],
  },
  {
    id: 5,
    name: "Dossier readiness ",
    subFilters: ["Ready -US", "Ready - EU", "Early development", "Late stage"],
  },
  { id: 6, name: "Curated  ", subFilters: ["Curated dossiers only"] },
  {
    id: 8,
    name: "Supply ",
    subFilters: ["With supply only", "Tech transfer only"],
  },
];

export const searchResult = [
  {
    title: "Dexlansoprazole",
    code: " 10115",
    locations: "US, Global",
    dosStatus: "Ready, US BE available",
    curated: "Yes",
    mfg: " All regulated markets",
    com: "IND",
    usConDate: "Mar 2027 ",
    supply: "Tech Transfer Only",
    type: "Exclusivity by market",
    descp:
      "An established company based in India, is looking to out-license the dossier along with tech transfer rights. The buyer would need to submit the ANDA in his name. The seller is willing to support tech transfer  and commercial supplies will be from the new site. No limited period supply option with the seller.",
    resCapsules: "30/60mg",
    offDay: "5",
    type: "Exclusivity by market",
  },
  {
    title: "Dexlansoprazole",
    code: " 10115",
    locations: "US, ROW",
    dosStatus: "Late stage, EU BE available",
    curated: "Yes",
    mfg: " All regulated markets",
    com: "EU",
    usConDate: "Mar 2027 ",
    supply: "Tech Transfer Only",
    type: "Exclusivity by market",
    descp:
      "An emerging company based in EU, is looking to out-license the dossier and will support commercial supplies through own manufacturing plant. The product is under development and the seller has successfully completed EU BE study. While this BE study will not be made available, it certainly serves as proof of concept of readiness of the dossier. The buyer will need to invest in BE study for markets of interest.",
    resCapsules: "30/60mg",
    offDay: "5",
    type: "Exclusivity by market",
  },
  {
    title: "Dexlansoprazole",
    code: " 10115",
    locations: "US, Global",
    dosStatus: "Ready, US BE available",
    curated: "Yes",
    mfg: " All regulated markets",
    com: "IND",
    usConDate: "Mar 2027 ",
    supply: "Tech Transfer Only",
    type: "Exclusivity by market",
    descp:
      "An established company based in India, is looking to out-license the dossier along with tech transfer rights. The buyer would need to submit the ANDA in his name. The seller is willing to support tech transfer  and commercial supplies will be from the new site. No limited period supply option with the seller.",
    resCapsules: "30/60mg",
    offDay: "5",
    type: "Exclusivity by market",
  },
];

export const about = [
  {
    title: "About the molecule ",
    descp:
      "Dexlansoprazole is a proton pump inhibitor (PPI) that reduces stomach acid. It is available as delayed-release capsules, both brand and generic, in the US. On October 17, 2017, Dexilant Solutab® (disintegrating tablets) was withdrawn from the US market. Dexlansoprazole treats acid-related conditions such as erosive esophagitis and gastroesophageal reflux disease (GERD). It is also used for Symptomatic Non-Erosive GERD and is indicated for patients aged 12 and older.",
  },
  {
    title: "About the molecule ",
    descp:
      "Dexlansoprazole is a proton pump inhibitor (PPI) that reduces stomach acid. It is available as delayed-release capsules, both brand and generic, in the US. On October 17, 2017, Dexilant Solutab® (disintegrating tablets) was withdrawn from the US market. Dexlansoprazole treats acid-related conditions such as erosive esophagitis and gastroesophageal reflux disease (GERD). It is also used for Symptomatic Non-Erosive GERD and is indicated for patients aged 12 and older.",
  },
  {
    title: "About the molecule ",
    descp:
      "Dexlansoprazole is a proton pump inhibitor (PPI) that reduces stomach acid. It is available as delayed-release capsules, both brand and generic, in the US. On October 17, 2017, Dexilant Solutab® (disintegrating tablets) was withdrawn from the US market. Dexlansoprazole treats acid-related conditions such as erosive esophagitis and gastroesophageal reflux disease (GERD). It is also used for Symptomatic Non-Erosive GERD and is indicated for patients aged 12 and older.",
  },
];

export const list = [
  { value: "1", label: "Antiretrovirals" },
  { value: "2", label: "HCV's" },
  { value: "3", label: "Immunosuppressants" },
  { value: "4", label: "Pain and anti-inflammatory range" },
  { value: "5", label: "Anti-infectives" },
  { value: "6", label: "Antihypertensives" },
  { value: "7", label: "Antidiabetics" },
  { value: "8", label: "Vit D analogues" },
  { value: "9", label: "Antipsychotics" },
  { value: "10", label: "Anti-hyperlipidaemic" },
  { value: "11", label: "Anti malarial" },
  { value: "12", label: "Gastro" },
  { value: "13", label: "Anthelmintics" },
  { value: "14", label: "Steroids" },
  { value: "15", label: "PPI's" },
  { value: "16", label: "Osmotic laxatives" },
  { value: "17", label: "Alzheimer's" },
  { value: "18", label: "COPD" },
  { value: "19", label: "Antidepressants" },
  { value: "20", label: "Ulcerative colitis" },
  { value: "21", label: "Gout" },
  { value: "22", label: "Hyperthyroidism" },
  { value: "23", label: "Antifungal" },
  { value: "24", label: "Antiepileptics" },
  { value: "25", label: "Antihistamine" },
  { value: "26", label: "Hyperkalaemia" },
  { value: "27", label: "Immunomodulators" },
  { value: "28", label: "Potent compounds (non Onco)" },
];

export const list2 = [
  { value: "1", label: "Aerosol" },
  { value: "2", label: "Aerosol, Metered" },
  { value: "3", label: "Capsule" },
  { value: "4", label: "Capsule, Delayed release" },
  { value: "5", label: "Capsule, Extended release" },
  { value: "6", label: "Cream" },
  { value: "7", label: "Dressing" },
  { value: "8", label: "Enema" },
  { value: "9", label: "Gas" },
  { value: "10", label: "Granule" },
  { value: "11", label: "Granule, Delayed Release" },
  { value: "12", label: "Granule, Extended Release" },
  { value: "13", label: "Gum, Chewing" },
  { value: "14", label: "Implant" },
  { value: "15", label: "Injectable" },
  { value: "16", label: "Injectable, Long-Acting" },
  { value: "17", label: "Intrauterine Device" },
  { value: "18", label: "Liquid/Drops" },
  { value: "19", label: "Lotion" },
  { value: "20", label: "Lozenge" },
  { value: "21", label: "Multiple" },
  { value: "22", label: "Ointment" },
  { value: "23", label: "Other Special Forms" },
  { value: "24", label: "Ovule" },
  { value: "25", label: "Paste" },
  { value: "26", label: "Patch" },
  { value: "27", label: "Pellet" },
  { value: "28", label: "Powder" },
  { value: "29", label: "Powder, Delayed Release" },
  { value: "30", label: "Powder, Extended Release" },
  { value: "31", label: "Ring" },
  { value: "32", label: "Shampoo" },
  { value: "33", label: "Soap" },
  { value: "34", label: "Solution" },
  { value: "35", label: "Spray" },
  { value: "36", label: "Suppository" },
  { value: "37", label: "Suspension" },
  { value: "38", label: "Suspension, Delayed release" },
  { value: "39", label: "Suspension, Extended Release" },
  { value: "40", label: "Tablet" },
  { value: "41", label: "Tablet, Delayed Release" },
  { value: "42", label: "Tablet, Extended Release" },
  { value: "43", label: "Tablet, Orally Disintegrating" },
  { value: "44", label: "Tablet, Sublingual" },
  { value: "45", label: "Tablet, Sublingual, Delayed Release" },
  { value: "46", label: "Tampon" },
  { value: "47", label: "Gel" },
];

export const list3 = [
  { value: "1", label: "Oral" },
  { value: "2", label: "Sublingual" },
  { value: "3", label: "Buccal" },
  { value: "4", label: "Rectal" },
  { value: "5", label: "Topical" },
  { value: "6", label: "Otic" },
  { value: "7", label: "Transdermal" },
  { value: "8", label: "Inhalation" },
  { value: "9", label: "Intravenous (IV)" },
  { value: "10", label: "Intramuscular (IM)" },
  { value: "11", label: "Subcutaneous (SC or SQ)" },
  { value: "12", label: "Intradermal (ID)" },
  { value: "13", label: "Intrathecal" },
  { value: "14", label: "Intranasal" },
  { value: "15", label: "Intraocular" },
];
export const list4 = [
  { value: "1", label: "Technology " },
  { value: "2", label: "Issue" },
  { value: "3", label: "other error" },
];
export const list5 = [
  { value: "1", label: "Complexity" },
  { value: "2", label: "Issue" },
  { value: "3", label: "other error" },
];
