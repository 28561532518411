import React from "react";
import { useNavigate } from "react-router-dom";
import FirstButton from "../../common/button";

const MarketPlaceFAQ = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/help/faq");
  };
  const data = [
    {
      title: "Global Licensing Hub",
      desc: "PharmaDigm serves as a global hub for the licensing of finished dosage form dossiers or finished dosage suppliers. Connect with partners across the world and unlock a wealth of opportunities.",
    },
    {
      title: "Intelligence and Simplicity",
      desc: "In a world where information is at the click of a button, we bring intelligence to deal-making. Our platform simplifies the entire process, empowering you to transact with ease.",
    },
    {
      title: "Efficiency at Your Fingertips",
      desc: "Say goodbye to traditional constraints. PharmaDigm puts efficiency at your fingertips, allowing you to navigate the pharmaceutical landscape with precision and speed.",
    },
    {
      title: "Embracing the Future",
      desc: "As we grow, we recognize the power of the digital age. PharmaDigm embraces the future, offering a paradigm shift that aligns with the needs of the modern pharmaceutical industry.",
    },
  ];

  return (
    <>
      {/* <div className="comment" style={{margin:'4rem 0'}}>
        <div className="comment_container">
          <div>
            <div className="comments-cont">
              <div className="comment_user">
                <img className="profile_img" src={user2} alt="" />
              </div>
              <div className="comment_text">
                <p className="comment_primary">
                  We've used the PharmaDigm Market Place to post technical
                  challenges as well as respond to technology calls for
                  potential IP out-licensing in non-competition industries.
                </p>
                <p className="comment_secondary"> Austin zolda, R&D Director</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default MarketPlaceFAQ;
