import React from "react";

import img from "../../assets/images/HowItWorks/howItWorks.jpg";
import img1 from "../../assets/images/HowItWorks/ProductExploration.jpeg";
import img2 from "../../assets/images/HowItWorks/ComparativeAnalysis.jpg";
import img3 from "../../assets/images/HowItWorks/RequestTermSheet.jpg";
import img4 from "../../assets/images/HowItWorks/TermSheetExecution.jpg";
import img5 from "../../assets/images/HowItWorks/Duediligence.jpg";
import img6 from "../../assets/images/HowItWorks/ServiceModule.jpg";
import { useNavigate } from "react-router-dom";

import FirstButton from "../../common/button";
const goalList = [
  {
    id: 1,
    image: img,
    title: "Registration",
    discription:
      "Start by registering on PharmaDigm to unlock the buyer experience FREE OF COST. It’s quick, easy, and opens the door to a world of dossiers, suppliers and services",
  },
  {
    id: 2,
    image: img1,
    title: "Product Exploration",
    discription:
      "Navigate our user-friendly interface to explore a vast array of finished dosage products or the right supplier for your needs. Use the finder or apply specific filter criteria to streamline your search.",
  },
  {
    id: 3,
    image: img2,
    title: "Comparative Analysis ",
    discription:
      "Once you find a product of interest, delve into detailed comparisons. Evaluate available dossiers, assess GMP compliance, explore one-time fees, and review supply prices.",
  },
  {
    id: 4,
    image: img3,
    title: "Request a Term Sheet",
    discription:
      "Ready to move forward? Request a term sheet tailored to your needs. The term sheet provides further details and is customized based on your volume projections",
  },
  {
    id: 5,
    image: img4,
    title: "Term Sheet Execution",
    discription:
      "Execute the term sheet and pay a nominal refundable fee to PharmaDigm. Congratulations! You are now empowered to directly engage with the supplier, progressing through due diligence and contract stages",
  },
  {
    id: 6,
    image: img5,
    title: "Due diligence",
    discription:
      "Need additional support? PharmaDigm offers curated dossiers where due diligence is pre-performed. We also work and support suppliers to improve the quality of dossiers and data, wherever required",
  },
  {
    id: 7,
    image: img6,
    title: "Service Module",
    discription:
      "Make your deal even more seamless by exploring our service module. Choose from a list of trusted service providers to complement your deal-making journey",
  },
];
const Goals = () => {
  const navigate = useNavigate();
  return (
    <div style={{ padding: "3rem" }}>
      <h4 className="goal_head about_heading">How it Works</h4>

      <p className="goal_p1">
        Your simplified gateway to pharmaceutical deal-making
      </p>
      <div className="goal_cont">
        {goalList.map((item, index) => (
          <div key={index} className="goals_cont2">
            <div className="only-d-flex about-class justify-between">
              <div className="golesImg">
                <img
                  src={item.image}
                  style={{
                    width: "180px",
                    height: "180px",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
              </div>
              <div className="w60">
                <div className="goal_title">
                  {/* <img src={item.image} alt="" width="80px" height="80px" /> */}
                  <h4 className="goal_title1">{item.title}</h4>
                </div>
                <p className="goal_p2 text-justify">{item.discription}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="bg-white p-4"> */}
      <FirstButton
        className="btn my-4 mx-auto d-flex"
        buttonText="Get Started"
        onClick={() => navigate("/login")}
      />
      {/* </div> */}
    </div>
  );
};

export default Goals;
