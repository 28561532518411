import React from "react";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Autocomplete, TextField } from "@mui/material";
const colourStyles = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    background: "#f5f5f5",
    fontSize: "1.3rem",
    fontWeight: "500",
    padding: ".6rem 0",
  }),

  menuList: (styles) => ({
    ...styles,
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "0 1rem 2rem rgba(0,0,0,.1)",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    fontSize: "1.3rem",
    background: isFocused ? "#4c8df5" : isSelected ? "#fff" : undefined,
    zIndex: 1,
    color: isFocused ? "#fff" : isSelected ? "#000" : undefined,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

export default function Dropdown({ MenuList, placeholder, dValue }) {
  return (
    // <FormControl fullWidth>
    //   <InputLabel id="demo-simple-select-label" className="mb-5">
    //     {placeholder?.label}
    //   </InputLabel>
    //   <Select
    //     name="filter"
    //     defaultValue={dValue}
    //     options={MenuList}
    //     className="basic-multi-select"
    //     classNamePrefix="select"
    //     styles={colourStyles}
    //     // placeholder={placeholder}
    //   />
    // </FormControl>
    <Autocomplete
      name="filter"
      // defaultValue={dValue}
      options={MenuList}
      className="basic-multi-select"
      classNamePrefix="select"
      styles={colourStyles}
      renderInput={(params) => (
        <TextField {...params} label={placeholder?.label} />
      )}
    />
  );
}
