import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
const SideBar = (props) => {
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState(false);
  const email = localStorage.getItem("email");
  useEffect(() => {
    setOpenNav(props.classValue);
  }, [props]);

  const handleRouting = (value) => {
    props.getValue(false);
    navigate(value);
  };
  const handleClose = (value) => {
    props.getValue(value);
  };
  return (
    <div className={openNav ? "overlay" : ""}>
      <div className={`sidebar ${openNav ? "openSidebar" : ""}`}>
        <div className="sidebar_container">
          <div
            onClick={(val) => handleClose((val = false))}
            className="sidebar_close"
          >
            <CancelIcon style={{ color: "white", fontSize: "4rem" }} />
          </div>
          <div className="sidebar_content">
            <ul className="sidebar_ul">
              <li
                onClick={(route) => handleRouting((route = "/"))}
                className="sidebar_link"
              >
                Home
              </li>

              <li
                onClick={(route) => handleRouting((route = "about-us"))}
                className="sidebar_link"
              >
                About Us
              </li>

              <li
                onClick={(route) => handleRouting((route = "faq"))}
                className="sidebar_link"
              >
                FAQs
              </li>

              <li
                onClick={(route) => handleRouting((route = "contact-us"))}
                className="sidebar_link"
              >
                Contact-us
              </li>

              <li
                onClick={(route) => handleRouting((route = "/login"))}
                className="sidebar_link"
              >
                Login
              </li>
              <li
                onClick={(route) => handleRouting((route = "/sign-up"))}
                className="sidebar_link"
              >
                Signup
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SideBar;
