import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FirstButton from "../common/button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  filters,
  searchResult,
  about,
  list,
  list2,
  list3,
  list4,
  list5,
} from "../data/SearchData";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AccordionDetails from "@mui/material/AccordionDetails";
import { TextField } from "@material-ui/core";
import BreadCrumbs from "./BreadCrumbs";
import { Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import Dropdown from "../common/dropdown";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
const ProductSearchPage = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const [searchTerms, setSearchTerms] = useState({});
  const urls = [
    { name: "Home", url: "/home" },
    { name: "Dossier Search", url: "" },
  ];
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  const handleSubmit = () => {};
  const handleChange = (event, newValue) => {
    if (newValue === "1") {
      navigate("/dossier-search");
    } else if (newValue === "2") {
      navigate("/supplier-search");
    }
    setValue(newValue);
  };
  const handleSearchChange = (filterId, e) => {
    setSearchTerms({
      ...searchTerms,
      [filterId]: e.target.value.toLowerCase(),
    });
  };
  return (
    <div className="search backgroundColor">
      <BreadCrumbs array={urls} />

      <div className="only-d-flex justify-center align-center mt-3">
        <Tabs
          value={value}
          onChange={handleChange}
          className="w30 "
          sx={{
            "& button": { borderRadius: 2 },
            "& button:hover": {
              backgroundColor: "#7915cf",
              color: "white",
              opacity: 0.55,
            },
            "& button:focus": {
              backgroundColor: "#7915cf0",
              color: "white",
            },
            "& button:active": {
              backgroundColor: "#7915cf",
              color: "white",
            },
            "& .MuiTabs-indicator": {
              display: "none", // Hide the indicator line
            },
          }}
        >
          <Tab
            className={
              value === "1" ? "fs-14 fw-600  activeTab" : "fs-14 fw-600 "
            }
            value="1"
            label="Dossier Finder"
          />
          <Tab
            className={
              value === "2" ? "fs-14 fw-600  activeTab" : "fs-14 fw-600 "
            }
            value="2"
            label="Supplier Finder"
          />
        </Tabs>
      </div>
      <div className="searchInput">
        <input
          className="login_input bg-white"
          placeholder="Search"
          style={{ width: "60%" }}
        />

        <FirstButton
          buttonText="Submit"
          onClick={handleSubmit}
          className="btn ms-2"
        />
      </div>
      <div className="dflex ">
        <div className="w20">
          <h2 className="main_imgcont2 fs-16 my-2">Filters</h2>
        </div>
        <div className="w60">
          <h2 className="main_imgcont2 fs-16 my-2 ">
            {searchResult.length} dossiers found
          </h2>
        </div>
      </div>
      <div className="dflex ">
        <div className="w20 m121">
          {/* {filters &&
            filters.map((item, index) => (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <h4>{item.name}</h4>
                </AccordionSummary>
                {item.id !== 1 && item?.id !== 3 && (
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search"
                    fullWidth
                    value={searchTerms[item.id] || ""}
                    onChange={(e) => handleSearchChange(item.id, e)}
                    style={{ marginBottom: "1rem" }}
                  />
                )}
                {item.subFilters &&
                  item.subFilters.map((itemSub, index) => (
                    <AccordionDetails className="px-3 py-2">
                      {item.id === 1 ? (
                        <Dropdown
                          MenuList={
                            index === 0 ? (
                              list
                            ) : index === 1 ? (
                              list2
                            ) : index === 2 ? (
                              list3
                            ) : index === 3 ? (
                              list4
                            ) : index === 4 ? (
                              list5
                            ) : (
                              <></>
                            )
                          }
                          dValue={
                            index === 0 ? (
                              { value: "1", label: "Therapeutic Category " }
                            ) : index === 1 ? (
                              { value: "1", label: "Dosage Form " }
                            ) : index === 2 ? (
                              { value: "1", label: "Route Of Administration " }
                            ) : index === 3 ? (
                              {}
                            ) : index === 4 ? (
                              {}
                            ) : (
                              <></>
                            )
                          }
                        />
                      ) : (
                        <FormControlLabel
                          control={
                            item.id === 3 ? (
                              <input
                                type="date"
                                defaultValue="Small"
                                className="p-2"
                              />
                            ) : (
                              <Checkbox
                                sx={{
                                  color: " #00adfc",
                                  "&.Mui-checked": { color: "#00adfc" },
                                  "& .MuiSvgIcon-root": { fontSize: 24 },
                                }}
                              />
                            )
                          }
                          labelPlacement={item.id === 3 ? "start" : "end"}
                          label={
                            <span
                              style={
                                item.id === 3 && itemSub === "To"
                                  ? {
                                      fontSize: "1.4rem",
                                      marginRight: "7.2rem",
                                      fontWeight: "400",
                                    }
                                  : item.id === 3
                                  ? {
                                      fontSize: "1.4rem",
                                      marginRight: "5rem",
                                      fontWeight: "400",
                                    }
                                  : {
                                      fontSize: "1.4rem",
                                      marginLeft: "1rem",
                                      fontWeight: "400",
                                    }
                              }
                            >
                              {itemSub}
                            </span>
                          }
                        />
                      )}
                    </AccordionDetails>
                  ))}
              </Accordion>
            ))} */}

          {filters &&
            filters.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <h4>{item.name}</h4>
                </AccordionSummary>
                <AccordionDetails className="px-3 py-2">
                  {/* Render search input only if the filter contains checkboxes */}
                  {item.subFilters && item.id !== 1 && item.id !== 3 && (
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      fullWidth
                      value={searchTerms[item.id] || ""}
                      onChange={(e) => handleSearchChange(item.id, e)}
                      style={{ marginBottom: "1rem" }}
                    />
                  )}

                  {/* Filter and render sub-filters (checkboxes) based on search term */}
                  {item.subFilters &&
                    item.subFilters
                      .filter(
                        (itemSub) =>
                          !searchTerms[item.id] ||
                          itemSub.toLowerCase().includes(searchTerms[item.id])
                      )
                      .map((itemSub, subIndex) => (
                        <AccordionDetails
                          key={subIndex}
                          // className="px-3 py-2"
                          className={
                            item?.id === 1 && subIndex === 2
                              ? "px-3 py-2 mb-40"
                              : "px-3 py-2"
                          }
                        >
                          {item.id === 1 ? (
                            <Dropdown
                              MenuList={
                                subIndex === 0 ? (
                                  list
                                ) : subIndex === 1 ? (
                                  list2
                                ) : subIndex === 2 ? (
                                  list3
                                ) : subIndex === 3 ? (
                                  list4
                                ) : subIndex === 4 ? (
                                  list5
                                ) : (
                                  <></>
                                )
                              }
                              dValue={
                                subIndex === 0 ? (
                                  { value: "1", label: "Therapeutic Category " }
                                ) : subIndex === 1 ? (
                                  { value: "1", label: "Dosage Form " }
                                ) : subIndex === 2 ? (
                                  {
                                    value: "1",
                                    label: "Route Of Administration ",
                                  }
                                ) : subIndex === 3 ? (
                                  {}
                                ) : subIndex === 4 ? (
                                  {}
                                ) : (
                                  <></>
                                )
                              }
                              placeholder={
                                subIndex === 0 ? (
                                  { value: "1", label: "Therapeutic Category " }
                                ) : subIndex === 1 ? (
                                  { value: "1", label: "Dosage Form " }
                                ) : subIndex === 2 ? (
                                  {
                                    value: "1",
                                    label: "Route Of Administration ",
                                  }
                                ) : (
                                  <></>
                                )
                              }
                            />
                          ) : (
                            <FormControlLabel
                              control={
                                item.id === 3 ? (
                                  <input
                                    type="date"
                                    defaultValue="Small"
                                    className="p-2"
                                  />
                                ) : (
                                  <Checkbox
                                    sx={{
                                      color: " #00adfc",
                                      "&.Mui-checked": { color: "#00adfc" },
                                      "& .MuiSvgIcon-root": { fontSize: 24 },
                                    }}
                                  />
                                )
                              }
                              labelPlacement={item.id === 3 ? "start" : "end"}
                              label={
                                <span
                                  style={
                                    item.id === 3 && itemSub === "After"
                                      ? {
                                          fontSize: "1.4rem",
                                          marginRight: "5.2rem",
                                          fontWeight: "400",
                                        }
                                      : item.id === 3
                                      ? {
                                          fontSize: "1.4rem",
                                          marginRight: "4rem",
                                          fontWeight: "400",
                                        }
                                      : item.id === 6
                                      ? {
                                          fontSize: "1.3rem",
                                          marginRight: "0rem",
                                          fontWeight: "400",
                                        }
                                      : {
                                          fontSize: "1.4rem",
                                          marginLeft: "1rem",
                                          fontWeight: "400",
                                        }
                                  }
                                >
                                  {itemSub}
                                </span>
                              }
                            />
                          )}
                        </AccordionDetails>
                      ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
        <div className="w60">
          {searchResult &&
            searchResult.map((sitem, index) => (
              <div className="ph-spcardmain bg-white" key={sitem.id}>
                <div className="ph-cardsphead">
                  <div>
                    <p className="pdcurated ">
                      <span className="mb-1 me-1">
                        <CheckCircleSharpIcon />
                      </span>
                      PD Curated
                    </p>
                    <div className="d-flex">
                      <h2
                        className="fs-24 fw-600 sidebar_close title-hover"
                        onClick={() => navigate("/product-details")}
                      >
                        {sitem.title}
                      </h2>
                    </div>
                    <p className="ph-cardspp">
                      Delayed release capsules {sitem.resCapsules}
                    </p>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <FirstButton
                      buttonText="Enquire"
                      className="enquire-btn"
                      onClick={() => navigate("/product-term-sheet")}
                    />
                    <p className="offer-font">
                      Offer in {sitem.offDay} business days
                    </p>
                  </div>
                </div>
                <div className="ph-cardspbody">
                  <div>
                    <p className="ph-cardspp">
                      Available geographies - {sitem.locations}
                    </p>
                    <p className="ph-cardspp">
                      Dossier status - {sitem.dosStatus}
                    </p>
                    <p className="ph-cardspp">Curated - {sitem.curated}</p>
                    <p className="ph-cardspp">
                      Manufacturing GMP - {sitem.mfg}
                    </p>
                  </div>
                  <div>
                    <p className="ph-cardspp">
                      Country of manufacture - {sitem.com}
                    </p>
                    <p className="ph-cardspp">
                      US constraint date- {sitem.usConDate}
                    </p>
                    <p className="ph-cardspp">Supply- {sitem.supply}</p>
                    <p className="ph-cardspp">Type - {sitem.type}</p>
                  </div>
                </div>
                <div className="mt-2">
                  <h3 className="ph-cardspp">Code:{sitem.code}</h3>
                  <p className="ph-cardspp lh-base text-justify">
                    {sitem.descp}
                    <span
                      className="text-info sidebar_close"
                      onClick={() => navigate("/product-details")}
                    >
                      more...
                    </span>
                  </p>
                </div>
              </div>
            ))}
        </div>
        <div className="w20">
          {about &&
            about.map((item, index) => (
              <div className="ph-spcardmain2 bg-white">
                <h2 className="my-1 fw-600 fs-16">{item.title}</h2>
                <p className="about-molecule text-justify">{item.descp}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProductSearchPage;
