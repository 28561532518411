import React, { useState } from "react";
// import img1 from "../../assets/images/thumb1.png";
// import img2 from "../../assets/images/thumb2.png";
// import img3 from "../../assets/images/thumb3.png";
// import img4 from "../../assets/images/thumb4.png";
// import t1 from "../../assets/images/team1.png";
// import t2 from "../../assets/images/team2.png";
// import t3 from "../../assets/images/team3.png";
// import t4 from "../../assets/images/team4.png";
// import t5 from "../../assets/images/team5.png";
// import Slider from "react-slick";
import thankyou from "../../assets/images/thankU.png";
import { DialogContent, Dialog, IconButton } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FirstButton from "../../common/button";
import { Link, useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
const News = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 2,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleSubmit = () => {
    // navigate("/home");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* <div className="news" style={{ backgroundColor: "#ffffff" }}>
        <div style={{ textAlign: "center" }} className="news_container">
          <h1 className="news_heading dark">Client feedback</h1>
        </div>

        <div style={{ margin: "2rem auto 0 auto", textAlign: "center" }}>
          <Slider {...settings}>
            <div className="thumbnails text-center m-auto">
              <p className="news_title text-center dark mb-4">
                I’m writing to ask if you wouldn’t mind giving us a short
                testimonial for our website. We’re updating a few pages, and I’m
                hoping to add something from you. It would link back to your
                site, so it’s actually a good thing for your SEO.
              </p>
              <img
                style={{ textAlign: "center", margin: "auto" }}
                alt=""
                src={t1}
                className="w5"
              />
              <p className="news_title dark mt-2">John Doe, MD</p>
            </div>
            <div className="thumbnails ">
              <p className="news_title text-center dark mb-4">
                I’m writing to ask if you wouldn’t mind giving us a short
                testimonial for our website. We’re updating a few pages, and I’m
                hoping to add something from you. It would link back to your
                site, so it’s actually a good thing for your SEO.
              </p>
              <img
                style={{ textAlign: "center", margin: "auto" }}
                alt=""
                src={t2}
                className="w5"
              />
              <p className="news_title dark mt-2 ">John Doe, Director</p>
            </div>
            <div className="thumbnails">
              <p className="news_title text-center dark mb-4">
                I’m writing to ask if you wouldn’t mind giving us a short
                testimonial for our website. We’re updating a few pages, and I’m
                hoping to add something from you. It would link back to your
                site, so it’s actually a good thing for your SEO.
              </p>
              <img
                style={{ textAlign: "center", margin: "auto" }}
                alt=""
                src={t3}
                className="w5"
              />
              <p className="news_title dark mt-2">Shankar, Manager</p>
            </div>
            <div className="thumbnails">
              <p className="news_title text-center dark mb-4">
                I’m writing to ask if you wouldn’t mind giving us a short
                testimonial for our website. We’re updating a few pages, and I’m
                hoping to add something from you. It would link back to your
                site, so it’s actually a good thing for your SEO.
              </p>
              <img
                style={{ textAlign: "center", margin: "auto" }}
                alt=""
                src={t4}
                className="w5"
              />
              <p className="news_title dark mt-2">John Doe, Team Lead</p>
            </div>
            <div className="thumbnails">
              <p className="news_title text-center dark mb-4">
                I’m writing to ask if you wouldn’t mind giving us a short
                testimonial for our website. We’re updating a few pages, and I’m
                hoping to add something from you. It would link back to your
                site, so it’s actually a good thing for your SEO.
              </p>
              <img
                style={{ textAlign: "center", margin: "auto" }}
                alt=""
                src={t4}
                className="w5"
              />
              <p className="news_title dark mt-2">John Doe, Sales Manager</p>
            </div>
            <div className="thumbnails">
              <p className="news_title text-center dark mb-4">
                I’m writing to ask if you wouldn’t mind giving us a short
                testimonial for our website. We’re updating a few pages, and I’m
                hoping to add something from you. It would link back to your
                site, so it’s actually a good thing for your SEO.
              </p>
              <img
                style={{ textAlign: "center", margin: "auto" }}
                alt=""
                src={t1}
                className="w5"
              />
              <p className="news_title dark mt-2">John Doe, Manager </p>
            </div>
          </Slider>
        </div>
      </div> */}
      <div
        style={{
          textAlign: "center",
          margin: "8rem auto 10rem auto",
          background: "white",
        }}
        className="news_container"
      >
        <h1 className="news_heading dark">We'd love to hear from you! </h1>
        <p style={{ fontSize: "1.6rem" }}>
          Whether it is to know more about us, curious about features, a free
          trial or even feedback - we'd love to answer all your questions
        </p>
        <div
          className="d-flex news_form"
          style={{
            alignItems: "center",
            columnGap: "1rem",
            margin: "4rem auto",
            width: "60%",
            justifyContent: "center",
          }}
        >
          <input
            className="login_input"
            placeholder="Email Address"
            id="outlined-basic"
            variant="outlined"
          />
          <input
            className="login_input"
            placeholder="Full Name"
            id="outlined-basic"
            variant="outlined"
          />
          <FirstButton
            className="btn py-2"
            buttonText="Submit"
            width="30%"
            onClick={handleSubmit}
          />
        </div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          fullWidth={true}
          open={open}
        >
          <div className="d-flex justify-content-end align-items-center p-3">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
          <DialogContent dividers>
            <div className="px-4 py-2 text-center">
              <img alt="" src={thankyou} style={{ width: "20%" }} />
              <h4 className="fs-20 fw-700">
                Thank you for your interest in PharmaDigm, we will get in touch
                with you shortly.
              </h4>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
export default News;
