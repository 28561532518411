import React, { useState, useEffect } from "react";
import FirstButton from "../common/button";
import { Link, useNavigate } from "react-router-dom";
import { role } from "../utils/index";
import login from "../assets/images/login-1.jpeg";

const Registration = (props) => {
  const [username, setUsername] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  const handleSubmit = () => {
    // roles(username)
    // if (username && organisation) {
    navigate("/welcome");
    // }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "username") {
      setUsername(value);
    } else if (name === "organisation") {
      setOrganisation(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "mobile") {
      setMobile(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };
  return (
    <div className="login_main ">
      <div
        className="only-d-flex about-class  justify-between"
        style={{ height: "90vh" }}
      >
        <div
          className="w50 px-4 py-4 login-card-color text-white image-display-none"
          style={{
            backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0) 80%), url(${login})`,
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="login_heading text-white mb-4">Sign Up</div>
            <p className="fs-16 fw-600 mb-4">
              Join our marketplace & unlock benefits!
            </p>
            <div className="m-auto text-center ">
              <p className="fs-14 fw-500 ">
                Up-to-date Dossiers for Global markets
              </p>
              <p className="fs-14 fw-500">
                Curated information on multiple dosage forms
              </p>
              <p className="fs-14 fw-500">
                Plethora of suppliers to choose from
              </p>
              <p className="fs-14 fw-500">
                {" "}
                Additional services to complete the picture
              </p>
            </div>
          </div>
        </div>
        <div
          className="w50 px-4 py-4"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="reg_body">
            <div className="fs-20 fw-600 ">
              Just one step away from unlocking the pharma marketplace{" "}
            </div>
            <div>
              <p className="login_para ">
                <label> Full Name</label>
              </p>
              <input
                className="login_input "
                value={username}
                name="username"
                onChange={handleChange}
                placeholder=" Type full Name here"
                id="outlined-basic"
                label="User Name"
                variant="outlined"
              />

              <div className="only-d-flex about-class ">
                <div className="w50 pe-4">
                  <p className="login_para">
                    <label htmlFor=""> Organisation</label>
                  </p>

                  <input
                    className="login_input "
                    name="organisation"
                    onChange={handleChange}
                    placeholder="Organisation name"
                    value={organisation}
                    id="outlined-basic"
                    label="Organisation"
                    variant="outlined"
                  />
                </div>
                <div className="w50 ps-4">
                  <p className="login_para">
                    <label htmlFor=""> Mobile</label>
                  </p>
                  <input
                    className="login_input "
                    name="mobile"
                    type="number"
                    onChange={handleChange}
                    placeholder="Phone number"
                    value={mobile}
                    id="outlined-basic"
                    label="Mobile"
                    variant="outlined"
                  />
                </div>
              </div>

              <div className="only-d-flex about-class ">
                <div className="w50 pe-4">
                  <p className="login_para">
                    <label htmlFor=""> Email</label>
                  </p>
                  <input
                    className="login_input "
                    name="email"
                    onChange={handleChange}
                    placeholder=" Type email here"
                    value={email}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                  />
                </div>
                <div className="w50 ps-4">
                  <p className="login_para">
                    <label htmlFor=""> Choose a password</label>
                  </p>
                  <input
                    className="login_input "
                    name="password"
                    type="password"
                    onChange={handleChange}
                    placeholder="***********"
                    value={password}
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <FirstButton
                className="btn mb-4 mt-4 "
                buttonText="CREATE PROFILE"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
