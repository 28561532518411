import { AuthProvider } from "./Auth/AuthContext";
import AppRouter from "./router/AppRouter";
import { GoogleOAuthProvider } from '@react-oauth/google';
function App() {
  return (
    <GoogleOAuthProvider clientId="<your_client_id>">
    <AuthProvider>
      <div className="App">
        <AppRouter />
      </div>
    </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
