import React, { useEffect } from "react";
import FirstButton from "../common/button";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "./BreadCrumbs";
const SupplierDetails = () => {
  const urls = [
    { name: "Home", url: "/home" },
    { name: "Supplier Search", url: "/supplier-search" },
    { name: "Supplier Details", url: "" },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div className="p14 backgroundColor">
      <BreadCrumbs array={urls} />
      <div className="p-4 mt-4 bg-white">
        <div className="ps_main mb-4">
          <div>
            <h1 className="fw-600">Supplier Details</h1>
          </div>
          <FirstButton
            className="borderbtn"
            buttonText="Back to Search Results"
            width="30%"
            onClick={() => navigate("/supplier-search")}
          />
        </div>
        <div className="ps_main ">
          <p className="fs-16 fw-600">Supplier Code 1001</p>
          <FirstButton
            className="btn"
            buttonText="Request Term Sheet"
            width="30%"
            onClick={() => navigate("/supplier-term-sheet")}
          />
        </div>
      </div>
      <div>
        <hr />
        <div className="dfcg3">
          <div className="flex1">
            <h2 className="my-2  heading-bg-color text-white">Capabilities</h2>
            <table class="table table-striped bx-shadow">
              <tbody>
                {Capabilities &&
                  Capabilities.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <h2 className="my-2  heading-bg-color text-white">Commercials</h2>
            <table class="table table-striped bx-shadow">
              <tbody>
                {commercials &&
                  commercials.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="flex1">
            <h2 className="my-2  heading-bg-color text-white">
              Special technologies & Capabilities
            </h2>
            <table class="table table-striped bx-shadow">
              <tbody>
                {Sptc &&
                  Sptc.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <h2 className="my-2  heading-bg-color text-white">
              Manufacturing Details
            </h2>

            <table class="table table-striped bx-shadow">
              <tbody>
                {manufDetails &&
                  manufDetails.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <h2 className="my-2" style={{ textAlign: "center" }}>
        Track Record
      </h2>
      <div className="my-2 bx-shadow p2 bg-white">
        <p className="fs-16">Strengths of the seller</p>
        <p className="fs-16">Track record of the seller</p>
        <p className="fs-16">Star rating</p>
      </div>
      <FirstButton
        className="btn my-2"
        buttonText="Request Term Sheet"
        width="100%"
        onClick={() => navigate("/supplier-term-sheet")}
      />
    </div>
  );
};

export default SupplierDetails;

const Capabilities = [
  { label: "Manufacturing", value: "Yes" },
  { label: "Dosage forms", value: "Tablets 1 billion + " },
  { label: "", value: "Capsules   500 million " },
  { label: "Primary Packaging", value: "Yes" },
  { label: "Types", value: "Blister" },
  { label: "", value: "Bottle" },
  { label: "Secondary Packaging", value: "Yes" },
  {
    label: "",
    value: "carton , leaflet etc",
  },
  { label: "Testing & Release", value: "Yes" },
  { label: "Additional", value: "Supply Chain capabilities" },
];

const Sptc = [
  { label: "Onco", value: "US, Global" },
  { label: "Supply status", value: "Solid orals" },
  { label: "Hormonal", value: "Female" },
  { label: "Drug Device combinations", value: "" },
  {
    label: "Clinical batch manufacturing",
    value: "Small area for manufacturing clinical batches",
  },
];
const manufDetails = [
  { label: "Dosage Form", value: "GMP" },
  { label: "Tablets", value: "GMP1/ GMP 2/ GMP 3" },
  { label: "Dose form 2", value: "GMP 1" },
  { label: "Special capabilities", value: "" },
  { label: "Onco", value: "GMP 1" },
  { label: "Drug Device combi", value: "GMP 1/ GMP 2" },
  { label: "Region of Manufacture", value: "IND" },
  { label: "Other Accreditations", value: "Env - ISO 14001" },
  { label: "", value: "Safety ISO 45000, ISO 9001" },
];
const commercials = [
  { label: "Solid orals cc", value: "Per 1000s" },
  { label: "Tabs", value: "XX  Minimum XX tablets" },
  { label: "Caps", value: "XX  Minimum XX tablets" },
  { label: "Types", value: "Blister" },
  { label: "", value: "Bottle" },
];
