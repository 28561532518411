import React, { useState, useEffect } from "react";
import FirstButton from "../common/button";
import { Link, useNavigate } from "react-router-dom";
import { Card, IconButton, TextField, Button, Divider } from "@mui/material";
// import { role } from "../Utils/utils";
import login from "../assets/images/login-1.jpeg";
const SignUp = (props) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  const handleSubmit = () => {
    navigate("/registration");
  };
  const handleChange = (event) => {
    // const name=event.tareget.name
    const value = event.target.value;
    setEmail(value);
  };

  return (
    <div className="login_main">
      <div
        className="only-d-flex about-class justify-between"
        style={{ height: "90vh" }}
      >
        <div
          className="w50 px-4 py-4 login-card-color text-white image-display-none"
          style={{
            backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0) 80%), url(${login})`,
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="login_heading text-white mb-4">Sign Up</div>
            <p className="fs-20 fw-700 mb-4">
              Join our marketplace & unlock benefits!
            </p>
            <div className="m-auto text-center ">
              <p className="fs-16 fw-500 ">
                Up-to-date Dossiers for Global markets
              </p>
              <p className="fs-16 fw-500">
                Curated information on multiple dosage forms
              </p>
              <p className="fs-16 fw-500">
                Plethora of suppliers to choose from
              </p>
              <p className="fs-16 fw-500">
                {" "}
                Additional services to complete the picture
              </p>
            </div>
          </div>
        </div>
        <div
          className="w50 px-4 py-4 "
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="reg_body">
            <div className="fs-20 fw-600 ">
              Provide your business email below and we will send you an email to
              get started.
            </div>
            <div className="mt-4">
              <p className="login_para  ">
                <label> Email ID</label>
              </p>
              <div>
                <input
                  className="login_input  "
                  value={email}
                  name="email"
                  onChange={handleChange}
                  placeholder="Enter Your Email Id"
                  id="outlined-basic"
                  label="Email ID"
                  variant="outlined"
                />
              </div>
              {/* <TextField
                // className="login_input "
                label="Email ID"
                fullWidth
                variant="outlined"
                value={email}
                name="email"
                onChange={handleChange}
                placeholder="Enter Your Email Id"
              ></TextField> */}
            </div>

            <div className="mt-5">
              <FirstButton
                className="btn "
                buttonText="Sign Up"
                onClick={handleSubmit}
                width="40%"
              />
              <p className="login_last_para">
                Already registered ?
                <span>
                  <Link to="/login" className="login_link">
                    {" "}
                    Login
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
