// AppRouter.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../screens/Login";
import Forgot from "../screens/Forgot";
import Home from "../screens/Home";
import Header from "../navigation/Header";
import ProtectedRoute from "./ProtectedRoutes";
import Footer from "../navigation/Footer";
import HowItWorks from "../screens/HowItWorks";
import Welcome from "../screens/Welcome";
import Registration from "../screens/Registration";
import Faq from "../screens/Faq";
import ContactUs from "../screens/ContactUs";
import SignUp from "../screens/Signup";
import SupplierTermSheet from "../screens/SupplierTermSheet";
import Landing from "../screens/Landing";
import AboutUs from "../screens/AboutUs";
import ProductDetails from "../screens/ProductDetailsPage";
import SupplierSearchPage from "../screens/SupplierSearchPage";
import ProductSearchPage from "../screens/ProductSearchPage";
import SupplierDetails from "../screens/SupplierDetailsPage";
import ProductTermSheet from "../screens/ProductTermSheet";
import Service from "../screens/Service";
const AppRouter = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/dossier-search" element={<ProductSearchPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/sign-up" element={<SignUp />} />

        <Route path="/home" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/product-details" element={<ProductDetails />} />
        <Route path="/product-term-sheet" element={<ProductTermSheet />} />
        <Route path="/supplier-search" element={<SupplierSearchPage />} />
        <Route path="/supplier-details" element={<SupplierDetails />} />
        <Route path="/supplier-term-sheet" element={<SupplierTermSheet />} />
        <Route path="/service" element={<Service />} />
        {/* -------------------Admin Routes------------------------------------ */}
        <Route
          exact
          path="/admin"
          element={<ProtectedRoute allowedRoles={["admin"]} />}
        >
          {/* <Route exact path="/admin/data-form" element={<FillDetails />} /> */}
        </Route>
        {/*--------------------------------- Routes For Executive --------------------------------*/}
        <Route
          exact
          path="/user"
          element={<ProtectedRoute allowedRoles={["executive"]} />}
        ></Route>

        {/* ---------------------------executive and admin both----------------- */}
        <Route
          exact
          path="/"
          element={<ProtectedRoute allowedRoles={["executive", "admin"]} />}
        >
          {/* <Route exact path="/dashboard" element={<Dashboard />} /> */}
        </Route>
      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRouter;
