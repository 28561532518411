import React, { useEffect, useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import img1 from "../assets/images/p1.png";
import img2 from "../assets/images/p3.png";
import img3 from "../assets/images/p2.png";
import bgimg1 from "../assets/images/addedNew/dossier-raw.jpg.png";
import bgimg2 from "../assets/images/addedNew/supplier-raw.jpg.png";
import bgimg3 from "../assets/images/addedNew/services-raw.jpg.png";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  const [hoverIndex, setHoverIndex] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div style={{ padding: "12rem 4rem" }}>
      <div
        style={{ textAlign: "center" }}
        id="landing_primary"
        className="main_text"
      >
        <h1 className="primary_title">What do you want to do, today?</h1>
        <p className="m-5">
          <NotificationsIcon style={{ fontSize: "5rem", color: "#4c8df5" }} />
        </p>
      </div>

      <div className="showCards">
        <div style={{ textAlign: "center" }} className="showCards_container">
          <div className="cards">
            {data.map((item, index) => (
              // <div
              //   key={index}
              //   className="card"
              //   style={{
              //     backgroundImage:
              //       hoverIndex === index
              //         ? `linear-gradient(
              //   rgba(177, 70, 203, 0.8),
              //   rgba(75, 54, 120, 0.8)
              // ),
              // url(${item?.bgImg})`
              //         : `linear-gradient(
              //   to top, rgba(0, 0, 0, 0.8) 20% rgba(0, 0, 0, 0) 124%
              // ),
              // url(${item?.bgImg})`,
              //     backgroundSize: "cover",
              //     backgroundPosition: "center",
              //     transition: "background-image 0.3s ease-in-out",
              //   }}
              //   onMouseEnter={() => setHoverIndex(index)}
              //   onMouseLeave={() => setHoverIndex(null)}
              //   onClick={() => navigate(`${item.navigate}`)}
              // >
              //   <p className="fs-18 fw-600">{item.heading}</p>

              //   <h1 className="card_heading"> {item.title}</h1>
              //   <p
              //     style={{ marginBottom: "5rem" }}
              //     className="card_text card_height"
              //   >
              //     {item.desc}
              //   </p>
              // </div>
              <div
                key={index}
                className="card"
                style={{
                  backgroundImage:
                    hoverIndex === index
                      ? `linear-gradient(
                rgba(177, 70, 203, 0.8),
                rgba(75, 54, 120, 0.8)
              ),
              url(${item?.bgImg})`
                      : `linear-gradient(
                to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 180%
              ),
              url(${item?.bgImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  transition: "background-image 0.3s ease-in-out",
                }}
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(null)}
                onClick={() => navigate(`${item.navigate}`)}
              >
                <p className="fs-18 fw-600">{item.heading}</p>
                <h1 className="card_heading"> {item.title}</h1>
                <p
                  style={{ marginBottom: "5rem" }}
                  className="card_text card_height"
                >
                  {item.desc}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

const data = [
  {
    title: "Dossier Finder",
    desc: "Licensing, Acquisition of dossiers for Finished dosages for global markets",
    image: img1,
    heading: "Find the right product",
    navigate: "/dossier-search",
    bgImg: bgimg1,
  },
  {
    title: "Supplier Finder",
    desc: "Finished dosage suppliers, Manufacturing partners, CDMOs.",
    image: img3,
    heading: "Connect with the right supplier",
    navigate: "/supplier-search",
    bgImg: bgimg2,
  },
  {
    title: "Services Finder",
    desc: " Product Development,Analytical Services,Bio-availability/ Bio-equivalence services,Clinical supplies",
    heading: "Choose from an array of services ",
    image: img2,
    navigate: "/service",
    bgImg: bgimg3,
  },
];
