export const filters = [
  {
    id: 1,
    name: "Type",
    subFilters: [
      "Manufacturing",
      "Packaging",
      "Bulk Packaging",
      "QC testing and release",
    ],
  },
  {
    id: 2,
    name: "Dosage Forms",
    subFilters: [
      "Aerosol",
      "Aerosol, Metered",
      "Capsule",
      "Capsule, Delayed Release",
      "Capsule, Extended Release",
      "Cream",
      "Dressing",
      "Enema",
      "Gas",
      "Granule",
      "Granule, Delayed Release",
      "Granule, Extended Release",
      "Gum, Chewing",
      "Implant",
      "Injectable",
      "Injectable, Long-Acting",
      "Intrauterine Device",
      "Liquid/Drops",
      "Lotion",
      "Lozenge",
      "Multiple",
      "Ointment",
      "Other Special Forms",
      "Ovule",
      "Paste",
      "Patch",
      "Pellet",
      "Powder",
      "Powder, Delayed Release",
      "Powder, Extended Release",
      "Ring",
      "Shampoo",
      "Soap",
      "Solution",
      "Spray",
      "Suppository",
      "Suspension",
      "Suspension, Delayed Release",
      "Suspension, Extended Release",
      "Tablet",
      "Tablet, Delayed Release",
      "Tablet, Extended Release",
      "Tablet, Orally Disintegrating",
      "Tablet, Sublingual",
      "Tablet, Sublingual, Delayed Release",
      "Tampon",
      "Gel",
    ],
  },
  // { id: 3, name: "Constraint Date", subFilters: ["Before", "After"] },
  {
    id: 4,
    name: "Special capabilities",
    subFilters: [
      "Hormonal handling",
      " Oncology products",
      "Potent compounds capability",
      "Soft gelatin capsules",
    ],
  },
  {
    id: 5,
    name: "Plant Location",
    subFilters: ["Asia", " Europe", "North America", "Rest of the World"],
  },
  // { id: 6, name: "Curated  ", subFilters: ["Curated dossiers only"] },
  {
    id: 8,
    name: "Regulatory Compliance",
    subFilters: [
      "USFDA",
      "EMA (EU)",
      "TGA",
      "Health Canada",
      "MHRA",
      "Russian GMP",
      "ANVISA",
      "WHO",
      "PIC/S",
      "HSA",
      "PMDA",
    ],
  },
  // {
  //   id: 9,
  //   name: "Curated",
  //   subFilters: ["With supply only", "Tech transfer only"],
  // },
  // {
  //   id: 10,
  //   name: "Capacities",
  //   subFilters: ["With supply only", "Tech transfer only"],
  // },
];

export const searchResult = [
  {
    title: "Supplier 1001",
    code: " 10115",
    location: "Asia",
    offDay: 5,
    capabilities: [
      { id: 1, value: "Manufacturing" },
      { id: 2, value: "Packaging" },
      { id: 3, value: "Testing & Release" },
    ],
    dosage: [
      { id: 1, value: "Tablets" },
      { id: 2, value: "Capsules" },
      { id: 3, value: "Granules" },
    ],
    rc: [
      { id: 1, value: "USFDA" },
      { id: 2, value: "WHO" },
      { id: 3, value: "TGA" },
      { id: 4, value: "EU" },
      { id: 5, value: "MHRA" },
    ],
  },
  {
    title: "Supplier 1002",
    code: " 10115",
    location: "EU",
    offDay: 5,
    capabilities: [
      { id: 1, value: "Manufacturing" },
      { id: 2, value: "Packaging" },
      { id: 3, value: "Testing & Release" },
    ],
    dosage: [
      { id: 1, value: "Tablets" },
      { id: 2, value: "Capsules" },
      { id: 3, value: "Liquids" },
    ],
    rc: [
      { id: 1, value: "EU" },
      { id: 2, value: "MHRA" },
    ],
  },
  {
    title: "Supplier 1003",
    code: " 10115",
    location: "Asia",
    offDay: 5,
    capabilities: [
      { id: 1, value: "Manufacturing" },
      { id: 2, value: "Packaging" },
      { id: 3, value: "Testing & Release" },
    ],
    dosage: [
      { id: 1, value: "Tablets" },
      { id: 2, value: "Capsules" },
      { id: 3, value: "Topicals" },
    ],
    rc: [
      { id: 1, value: "USFDA" },
      { id: 2, value: "WHO" },
    ],
  },
];

export const about = [
  {
    title: "Find Dossiers Instead",
    subHead: "Need Specific Dossiers?",
    navi: "/dossier-search",
    descp:
      " Easily browse and discover the dossiers that match your exact needs. Find dossiers.",

    buttonText: "Find dossiers",
  },
  {
    title: "Additional Services Required",
    subHead: "Complete your project",
    buttonText: "Find services",
    navi: "",
    descp:
      " Access top-notch services – analytical labs, bioequivalence and more to make the deal seamless and end to end .",
  },
  {
    title: "Customized Search",
    subHead: "Looking for Something Unique?",
    buttonText: "Contact Us",
    navi: "",
    descp:
      " Tailor your search to find exactly what you’re looking for. Let us help you pinpoint the perfect match.",
  },
];
// export const list = [
//   { value: "1", label: "Therapeutic Category" },
//   { value: "2", label: "Issue" },
//   { value: "3", label: "other error" },
// ];
// export const list2 = [
//   { value: "1", label: "Dosage Form " },
//   { value: "2", label: "Issue" },
//   { value: "3", label: "other error" },
// ];
// export const list3 = [
//   { value: "1", label: "Route Of Administration " },
//   { value: "2", label: "Issue" },
//   { value: "3", label: "other error" },
// ];
export const list = [
  { value: "1", label: "Antiretrovirals" },
  { value: "2", label: "HCV's" },
  { value: "3", label: "Immunosuppressants" },
  { value: "4", label: "Pain and anti-inflammatory range" },
  { value: "5", label: "Anti-infectives" },
  { value: "6", label: "Antihypertensives" },
  { value: "7", label: "Antidiabetics" },
  { value: "8", label: "Vit D analogues" },
  { value: "9", label: "Antipsychotics" },
  { value: "10", label: "Anti-hyperlipidaemic" },
  { value: "11", label: "Anti malarial" },
  { value: "12", label: "Gastro" },
  { value: "13", label: "Anthelmintics" },
  { value: "14", label: "Steroids" },
  { value: "15", label: "PPI's" },
  { value: "16", label: "Osmotic laxatives" },
  { value: "17", label: "Alzheimer's" },
  { value: "18", label: "COPD" },
  { value: "19", label: "Antidepressants" },
  { value: "20", label: "Ulcerative colitis" },
  { value: "21", label: "Gout" },
  { value: "22", label: "Hyperthyroidism" },
  { value: "23", label: "Antifungal" },
  { value: "24", label: "Antiepileptics" },
  { value: "25", label: "Antihistamine" },
  { value: "26", label: "Hyperkalaemia" },
  { value: "27", label: "Immunomodulators" },
  { value: "28", label: "Potent compounds (non Onco)" },
];

export const list2 = [
  { value: "1", label: "Aerosol" },
  { value: "2", label: "Aerosol, Metered" },
  { value: "3", label: "Capsule" },
  { value: "4", label: "Capsule, Delayed release" },
  { value: "5", label: "Capsule, Extended release" },
  { value: "6", label: "Cream" },
  { value: "7", label: "Dressing" },
  { value: "8", label: "Enema" },
  { value: "9", label: "Gas" },
  { value: "10", label: "Granule" },
  { value: "11", label: "Granule, Delayed Release" },
  { value: "12", label: "Granule, Extended Release" },
  { value: "13", label: "Gum, Chewing" },
  { value: "14", label: "Implant" },
  { value: "15", label: "Injectable" },
  { value: "16", label: "Injectable, Long-Acting" },
  { value: "17", label: "Intrauterine Device" },
  { value: "18", label: "Liquid/Drops" },
  { value: "19", label: "Lotion" },
  { value: "20", label: "Lozenge" },
  { value: "21", label: "Multiple" },
  { value: "22", label: "Ointment" },
  { value: "23", label: "Other Special Forms" },
  { value: "24", label: "Ovule" },
  { value: "25", label: "Paste" },
  { value: "26", label: "Patch" },
  { value: "27", label: "Pellet" },
  { value: "28", label: "Powder" },
  { value: "29", label: "Powder, Delayed Release" },
  { value: "30", label: "Powder, Extended Release" },
  { value: "31", label: "Ring" },
  { value: "32", label: "Shampoo" },
  { value: "33", label: "Soap" },
  { value: "34", label: "Solution" },
  { value: "35", label: "Spray" },
  { value: "36", label: "Suppository" },
  { value: "37", label: "Suspension" },
  { value: "38", label: "Suspension, Delayed release" },
  { value: "39", label: "Suspension, Extended Release" },
  { value: "40", label: "Tablet" },
  { value: "41", label: "Tablet, Delayed Release" },
  { value: "42", label: "Tablet, Extended Release" },
  { value: "43", label: "Tablet, Orally Disintegrating" },
  { value: "44", label: "Tablet, Sublingual" },
  { value: "45", label: "Tablet, Sublingual, Delayed Release" },
  { value: "46", label: "Tampon" },
  { value: "47", label: "Gel" },
];

export const list3 = [
  { value: "1", label: "Oral" },
  { value: "2", label: "Sublingual" },
  { value: "3", label: "Buccal" },
  { value: "4", label: "Rectal" },
  { value: "5", label: "Topical" },
  { value: "6", label: "Otic" },
  { value: "7", label: "Transdermal" },
  { value: "8", label: "Inhalation" },
  { value: "9", label: "Intravenous (IV)" },
  { value: "10", label: "Intramuscular (IM)" },
  { value: "11", label: "Subcutaneous (SC or SQ)" },
  { value: "12", label: "Intradermal (ID)" },
  { value: "13", label: "Intrathecal" },
  { value: "14", label: "Intranasal" },
  { value: "15", label: "Intraocular" },
  { value: "16", label: "Intravenous (IV)" },
  { value: "17", label: "Intradermal (ID)" },
];

export const list4 = [
  { value: "1", label: "Technology " },
  { value: "2", label: "Issue" },
  { value: "3", label: "other error" },
];
export const list5 = [
  { value: "1", label: "Complexity" },
  { value: "2", label: "Issue" },
  { value: "3", label: "other error" },
];
