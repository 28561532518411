import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FirstButton from "../common/button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  filters,
  searchResult,
  about,
  list,
  list2,
  list3,
  list4,
  list5,
} from "../data/SupplierData";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AccordionDetails from "@mui/material/AccordionDetails";
import { TextField } from "@material-ui/core";
import BreadCrumbs from "./BreadCrumbs";
import { Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import Dropdown from "../common/dropdown";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const SupplierSearchPage = () => {
  const navigate = useNavigate();
  const [searchTerms, setSearchTerms] = useState({});
  const urls = [
    { name: "Home", url: "/home" },
    { name: "Supplier Search", url: "" },
  ];
  const [value, setValue] = useState("2");
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  const handleSubmit = () => {};
  const handleChange = (event, newValue) => {
    if (newValue === "1") {
      navigate("/dossier-search");
    } else if (newValue === "2") {
      navigate("/supplier-search");
    }
    setValue(newValue);
  };
  const handleSearchChange = (filterId, e) => {
    setSearchTerms({
      ...searchTerms,
      [filterId]: e.target.value.toLowerCase(),
    });
  };
  return (
    <div className="search backgroundColor">
      <BreadCrumbs array={urls} />
      <div className="only-d-flex justify-center align-center mt-3">
        <Tabs
          value={value}
          onChange={handleChange}
          className="w30 "
          sx={{
            "& button": { borderRadius: 2 },
            "& button:hover": {
              backgroundColor: "#7915cf",
              color: "white",
              opacity: 0.55,
            },
            "& button:focus": {
              backgroundColor: "#7915cf",
              color: "white",
            },
            "& button:active": {
              backgroundColor: "#7915cf",
              color: "white",
            },
            "& .MuiTabs-indicator": {
              display: "none", // Hide the indicator line
            },
          }}
        >
          <Tab
            className={
              value === "1" ? "fs-14 fw-600  activeTab" : "fs-14 fw-600 "
            }
            value="1"
            label="Dossier Finder"
          />
          <Tab
            className={
              value === "2" ? "fs-14 fw-600  activeTab" : "fs-14 fw-600 "
            }
            value="2"
            label="Supplier Finder  "
          />
        </Tabs>
      </div>
      <div className="searchInput">
        <input
          className="login_input bg-white"
          placeholder="Search"
          style={{ width: "60%" }}
        />

        <FirstButton
          buttonText="Submit"
          onClick={handleSubmit}
          className="btn ms-3"
        />
      </div>

      <div className="dflex ">
        <div className="w20">
          <h2 className="main_imgcont2 fs-16 my-2">Filters</h2>
        </div>
        <div className="w60">
          <h2 className="main_imgcont2 fs-16 my-2 ">
            {searchResult.length} suppliers found
          </h2>
        </div>
      </div>
      <div className="dflex ">
        <div className="w20  m121">
          {filters &&
            filters.map((item, index) => (
              <Accordion
                key={index}
                className={index === 8 ? "light-gray" : ""}
              >
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  className="checkBox "
                >
                  <h4>{item.name}</h4>
                </AccordionSummary>
                <AccordionDetails className="px-3 py-2">
                  {/* Search Box for the specific checkbox list */}
                  {item.id !== 0 && item?.id !== 3 && (
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      fullWidth
                      value={searchTerms[item.id] || ""}
                      onChange={(e) => handleSearchChange(item.id, e)}
                      style={{ marginBottom: "1rem" }}
                    />
                  )}

                  {item.subFilters &&
                    item.subFilters
                      .filter((itemSub) =>
                        itemSub
                          .toLowerCase()
                          .includes(searchTerms[item.id] || "")
                      )
                      .map((itemSub, subIndex) => (
                        <AccordionDetails className="px-3 py-2" key={subIndex}>
                          {item.id === 0 ? (
                            <Dropdown
                              MenuList={
                                index === 0 ? (
                                  list
                                ) : index === 1 ? (
                                  list2
                                ) : index === 2 ? (
                                  list3
                                ) : index === 3 ? (
                                  list4
                                ) : index === 4 ? (
                                  list5
                                ) : (
                                  <></>
                                )
                              }
                              dValue={
                                index === 0 ? (
                                  { value: "1", label: "Therapeutic Category" }
                                ) : index === 1 ? (
                                  { value: "1", label: "Dosage Form " }
                                ) : index === 2 ? (
                                  {
                                    value: "1",
                                    label: "Route Of Administration ",
                                  }
                                ) : index === 3 ? (
                                  {}
                                ) : index === 4 ? (
                                  {}
                                ) : (
                                  <></>
                                )
                              }
                            />
                          ) : (
                            <FormControlLabel
                              control={
                                item.id === 3 ? (
                                  <input
                                    type="date"
                                    defaultValue="Small"
                                    className="p-2"
                                  />
                                ) : (
                                  <Checkbox
                                    sx={{
                                      color: " #4c8df5",
                                      "&.Mui-checked": { color: "#4c8df5" },
                                      "& .MuiSvgIcon-root": { fontSize: 24 },
                                    }}
                                  />
                                )
                              }
                              labelPlacement={item.id === 3 ? "start" : "end"}
                              label={
                                <span
                                  style={
                                    item.id === 3 && itemSub === "After"
                                      ? {
                                          fontSize: "1.4rem",
                                          marginRight: "6.2rem",
                                          fontWeight: "400",
                                        }
                                      : item.id === 3
                                      ? {
                                          fontSize: "1.4rem",
                                          marginRight: "5rem",
                                          fontWeight: "400",
                                        }
                                      : {
                                          fontSize: "1.4rem",
                                          marginLeft: "1rem",
                                          fontWeight: "400",
                                        }
                                  }
                                >
                                  {itemSub}
                                </span>
                              }
                            />
                          )}
                        </AccordionDetails>
                      ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
        <div className="w60">
          {searchResult &&
            searchResult.map((sitem, index) => (
              <div className="ph-spcardmain bg-white" key={sitem.id}>
                <div className="ph-cardsphead">
                  <div>
                    <h2
                      className="fs-24 fw-600 sidebar_close title-hover"
                      onClick={() => navigate("/supplier-details")}
                    >
                      {sitem.title}
                    </h2>
                    <p className="location">
                      <span className="mb-1 me-1">
                        <LocationOnIcon />
                      </span>
                      {sitem.location}
                    </p>
                  </div>
                  <div style={{ textAlign: "end" }}>
                    <FirstButton
                      buttonText="Enquire"
                      className="enquire-btn"
                      onClick={() => navigate("/supplier-term-sheet")}
                    />
                    <p className="offer-font">
                      Offer in {sitem.offDay} business days
                    </p>
                  </div>
                </div>
                <div className="ph-cardspbody">
                  <div>
                    <p className="ssp_thead mb-0 ">Capabilities</p>
                  </div>
                  <div className="ssp_pilcont">
                    {sitem?.capabilities.map((citems, index) => (
                      <p className="vpil" key={citems.id}>
                        {citems.value}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="ph-cardspbody" style={{ border: "none" }}>
                  <div>
                    <p className="ssp_thead mb-0 ">Dosage</p>
                  </div>
                  <div className="ssp_pilcont">
                    {sitem?.dosage.map((citems, index) => (
                      <p className="vpil" key={citems.id}>
                        {citems.value}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="ph-cardspbody">
                  <div>
                    <p className="ssp_thead mb-0 ">Regulatory Compliance</p>
                  </div>
                  <div className="ssp_pilcont">
                    {sitem?.rc.map((citems, index) => (
                      <p className="vpil" key={citems.id}>
                        {citems.value}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="w20">
          {about &&
            about.map((item, index) => (
              <div className="ph-spcardmain2 bg-white">
                <h2 className="fs-20 fw-600 px1">{item.title}</h2>
                <h4 className="fs-16 fw-500 mb-3">{item?.subHead}</h4>
                <p className="about-molecule text-justify">{item.descp}</p>
                <FirstButton
                  className="btn my-4 mx-auto d-flex"
                  buttonText={item?.buttonText}
                  onClick={() => navigate(item?.navi)}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SupplierSearchPage;
